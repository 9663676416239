import React from 'react';
import {
  KnowMore,
  QuestionsForm,
  Seo,
  MainPost,
  PageHeader,
} from 'components/common';

// Just want to check, that it's running on our architecture
// Feel free to remove this console.log anytime
console.log('our-arch');

const indexPage = () => (
  <>
    <Seo title="Server 4 POS" />
    <PageHeader />
    <MainPost />
    {/* <KnowMore /> */}
    <QuestionsForm />
  </>
);

export default indexPage;
